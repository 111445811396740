import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import warterMark from '@/assets/js/warterMark'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ccsb',
    name: 'FactoryReport',
    component: () => import('../views/LedgerManagement/FactoryReport.vue'),
  },
  {
    path: '/jgsb',
    name: 'PurchaseReport',
    component: () => import('../views/LedgerManagement/PurchaseReport.vue'),
  },
  {
    path: '/sbls',
    name: 'ReportingHistory',
    component: () => import('../views/LedgerManagement/ReportingHistory.vue'),
  },
  {
    path: '/tb',
    name: 'Filling',
    component: () => import('../views/LedgerManagement/Filling.vue'),
  },
  {
    path: '/rwgl',
    name: 'rwgl',
    component: () => import('../views/TaskManagement/Index.vue'),
  },
  {
    path: '/xxsb',
    name: 'xxsb',
    component: () => import('../views/ReportingManagement/InformationReporting.vue'),
  },
  {
    path: '/xxsbls',
    name: 'xxsbls',
    component: () => import('../views/ReportingManagement/InformationReportingHistory.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
router.beforeEach(async (to, from, next) => {
  const token = sessionStorage.getItem('token')
  if (to.path !== '/login' && !token) next('/login')
  // if (process.env.NODE_ENV === 'production')
  warterMark.set('湖州数字化多功能综合调查平台')
  next()
})

export default router
