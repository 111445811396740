<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import './assets/style/style';
#app {
  z-index: 0;
}
</style>
