import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// Vue.prototype.$http = axios
// 全局导入所有组件     main.js文件
import Vant from 'vant'
import 'vant/lib/index.css'

const app = createApp(App)
app.use(store).use(router).use(Vant).mount('#app')
