import { ajaxT } from '@/assets/script/util'
// 登录
export function Login(params: any): Promise<any> {
  return ajaxT.post('/api/user/login', params).then((res) => {
    return res.data
  })
}
// 首页-获取问卷列表
export function getList(params: any): Promise<any> {
  return ajaxT.get('/api/history/index', { params }).then((res) => {
    return res.data
  })
}
// 首页-获取task
export function getTask(params: any): Promise<any> {
  return ajaxT.get('/api/history/task', { params }).then((res) => {
    return res.data
  })
}
// 出厂上报-获取信息
export function getProduct(params: any): Promise<any> {
  return ajaxT.get('/api/report/product', { params }).then((res) => {
    return res.data
  })
}
// 出厂上报-获取信息
export function getFactoryinfo(params: any): Promise<any> {
  return ajaxT.get('/api/report/factoryinfo', { params }).then((res) => {
    return res.data
  })
}
// 出厂上报-保存/修改
export function addFactory(params: any): Promise<any> {
  return ajaxT.post('/api/report/factoryadd', params).then((res) => {
    return res.data
  })
}
// 购进上报-获取信息
export function getPurchaseinfo(params: any): Promise<any> {
  return ajaxT.get('/api/report/purchaseinfo', { params }).then((res) => {
    return res.data
  })
}
// 购进上报-保存/修改
export function addPurchase(params: any): Promise<any> {
  return ajaxT.post('/api/report/purchaseadd', params).then((res) => {
    return res.data
  })
}
// 上传附件
export function upload(params: any): Promise<any> {
  return ajaxT.post('/api/report/upload', params).then((res) => {
    return res.data
  })
}
// 上报历史
export function monthlist(params: any): Promise<any> {
  return ajaxT.get('/api/report/monthlist', { params }).then((res) => {
    return res.data
  })
}
//获取design数据 初始填报
export function designData(params: any): Promise<any> {
  return ajaxT.post('/addons/customform/api_client/design_data', params).then((res) => {
    return res.data
  })
}
//获取design数据 :: 查看、编辑
export function editData(params: any): Promise<any> {
  return ajaxT.post('/VXsRTgfZqo.php/customform/index/edit_data', params).then((res) => {
    return res.data
  })
}

// 任务管理
export function taskInfo(params: any): Promise<any> {
  return ajaxT.get('/api/history/taskInfo', { params }).then((res) => {
    return res.data
  })
}
// 任务管理
export function taskAddr(params: any): Promise<any> {
  return ajaxT.get('/api/history/taskAddr', { params }).then((res) => {
    return res.data
  })
}
// 签到
export function renwuSign(params: any): Promise<any> {
  return ajaxT.post('/api/history/renwuSign', params).then((res) => {
    return res.data
  })
}
// 信息上报
export function investigateReport(params: any): Promise<any> {
  return ajaxT.post('/api/report/investigateReport', params).then((res) => {
    return res.data
  })
}
// 信息上报列表
export function investigateReportList(params: any): Promise<any> {
  return ajaxT.post('/api/report/investigateReportList', params).then((res) => {
    return res.data
  })
}
// 信息上报 详情
export function investigateReportInfo(params: any): Promise<any> {
  return ajaxT.post('/api/report/investigateReportInfo', params).then((res) => {
    return res.data
  })
}
// 任务管理-监管类查询
export function superviseTaskList(params: any): Promise<any> {
  return ajaxT.post('/api/history/superviseTaskList', params).then((res) => {
    return res.data
  })
}

/**
 *
 * @param params 发送验证码
 * @returns
 */
export function sendMessage(params: any): Promise<any> {
  return ajaxT.post('/api/user/smsLoginCode', params).then((res) => {
    return res.data
  })
}
