/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import { showFailToast } from 'vant'
// import { msgSuccess, msgWarning, msgError } from './message'
export const hasDev = process.env.NODE_ENV === 'development'
export const baseURL = process.env.NODE_ENV !== 'production' ? '/hzzdzx/' : ''

/**
 * 封装axios插件
 */
export const ajaxT = axios.create({
  baseURL,
  timeout: 600000, // 请求超时时间
})

// 请求失败处理函数
const onRejected = (error: any) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 403) {
      error.resolved = true
    }
    if (error.response.status === 401) {
      error.resolved = true

      sessionStorage.removeItem('userInfo')
      sessionStorage.removeItem('token')
      setTimeout(() => {
        window.location.reload()
      })
    }
    if (error.response.status === 500) {
      // Message.error('服务器出错了');
    }
  }
  return Promise.reject(error)
}

// request interceptor
ajaxT.interceptors.request.use((config: any) => {
  const headers = config.headers
  const token = JSON.parse(sessionStorage.getItem('token') as any)

  headers.Token = token

  return config
}, onRejected)

// response interceptor
ajaxT.interceptors.response.use((response: any) => {
  const headers = response.headers
  const code = response.data.code || 0
  // if (code !== 1) { showFailToast(response.data.message || '服务器出错了')
  // return false}
  // else return Promise.resolve(response.data)
  if (headers != null && headers['content-type'] && headers['content-type'].startsWith('application/json')) {
    if (response.status === 200) {
      if (code == 1) {
        return Promise.resolve(response.data)
      } else {
        showFailToast(response.data.msg)
        return Promise.reject(new Error(response.data))
      }
    } else {
      showFailToast(response.data.msg || '服务器出错了')
      return Promise.reject(new Error(response.data))
    }
  } else {
    return response
  }
}, onRejected)

/**
 * 重置路径
 *
 * @export
 * @param {*} path
 * @returns
 */
export function resetPath(path: string) {
  return path ? baseURL + path : ''
}
export function toStringParams(params: any) {
  const str = JSON.stringify(params)
  let stringParams = str.substring(1, str.length - 1)
  stringParams = stringParams.replace(/:/g, '=').replace(/,/g, '&')
  stringParams = stringParams.replace(/"/g, '')
  return stringParams
}
export function openExport(url: string, params: string) {
  window.open(`${baseURL + url}?${params}`)
}
